import { Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import { Products } from '@/services/ProductsDataService'
import MembersDataService from '@/services/MembersDataService'
import SysProduct from '@/types/SysProduct'
import SysMember from '@/types/SysMember'
import SysCart from '@/types/SysCart'
import BaseFunctions, { defaultClubId, defaultClubManagerGroupId } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import AuthService from '@/services/AuthService'

type productTypeDataReturn = { id: number; name: string; price: number; counter: number; onlySingle: boolean; }

export default class products extends Vue {
  $Message: any
  todaysDate = new Date().toISOString().split('T')[0]
  products: productTypeDataReturn[] = []
  memberId = this.parseURL()
  selectedSortingValue = 'Navn'
  currentSorting = 'produkt_navn:asc'
  productInfoModal = false
  productInfo = {} as SysProduct
  confirmationModal = false
  confirmationMessage = ''
  private clubIdOfMember = 0

  data () {
    return {
    }
  }

  @Watch('confirmationModal')
  onConfirmationModalChange (toggleValue: boolean) {
    if (!toggleValue) {
      this.confirmationMessage = ''
    }
  }

  public addProductToCart (id: number) {
    let tempProduct = {} as SysProduct
    let counter = 1
    const index = this.products.findIndex(el => el.id === id)
    if (index !== -1) {
      counter = this.products[index].counter
    }
    if (counter <= 0) {
      return this.$Message.danger({ text: 'Antal skal være større end 0' })
    }
    const getProduct = async (productId: number) => {
      await Products.ProductsDataService.get(productId.toString())
        .then((response) => {
          tempProduct = response.data
        })
        .catch((err) => {
          console.error(err)
        })
    }
    const promises = []
    promises.push(getProduct(id))
    Promise.all(promises)
      .then(() => {
        if (!localStorage.getItem('cart')) {
          localStorage.setItem('cart', JSON.stringify([]))
        }
        const cartItems: SysCart[] = JSON.parse(localStorage.getItem('cart')!)
        let newItem = {} as SysCart
        const extraItemData: any = {
          type: 999,
          clubId: this.clubIdOfMember,
          clubSaldoReduction: 0
        }

        newItem = {
          placeInCart: Number(cartItems.length),
          price: tempProduct.produkt_pris,
          productName: tempProduct.produkt_navn,
          productDescription: tempProduct.produkt_beskrivelse,
          dateInCart: this.todaysDate,
          expirationDate: null,
          playerRegistration: null,
          productId: Number(tempProduct.id),
          quantity: counter,
          licenseRenewal: null,
          clubLicenseRenewal: null,
          eventSignUp: null,
          teamRegistration: null,
          competitionFeeData: null,
          orderExtraData: extraItemData
        }
        cartItems.push(newItem)
        localStorage.setItem('cart', JSON.stringify(cartItems))
        this.confirmationMessage = counter.toString() + ' stk. ' + tempProduct.produkt_navn + ' er tilføjet til kurven'
        this.products[index].counter = 1
        this.confirmationModal = true
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public getMoreInfo (id: number) : void {
    Products.ProductsDataService.get(id.toString())
      .then((response) => {
        this.productInfo = response.data
        // console.log(this.productInfo)
        this.productInfoModal = true
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public toDanishDateString (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString)
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const userId = route.params.userSlug.toString().split('-')[0]
    if (Number(userId) !== undefined) {
      retVal = Number(userId)
    }
    return retVal
  }

  public checkPermissions () {
    let members: SysMember[] = []
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      // console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }

    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        members = response.data

        if (members.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Et eller andet gik galt' })
          AuthService.logout()
          CommonFunctions.redirectLogin()
          return
        } else {
          this.clubIdOfMember = (members[0].klubber_id === undefined || members[0].klubber_id.id === undefined || members[0].klubber_id.id === null || Number(members[0].klubber_id.id) < 1 ? defaultClubId : Number(members[0].klubber_id.id))
        }

        // if (members[0].user_id.usrgroup !== defaultClubManagerGroupId || this.memberId !== Number(members[0].id)) {
        if (this.memberId !== Number(members[0].id)) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveDateTimeFromServer () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        // this.todaysDate = response.data.split('T')[0]
        this.todaysDate = new Date(response.data).toISOString().split('T')[0]
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public retrieveProducts () : void {
    this.products = []
    let temp: SysProduct[] = []
    Products.ProductsDataService.getAll(this.currentSorting, null, 'produkt_type_id.visning=1')
      .then((response) => {
        temp = response.data
        for (const item of temp) {
          this.products.push({
            id: Number(item.id),
            name: item.produkt_navn,
            price: item.produkt_pris,
            counter: 1,
            onlySingle: item.produkt_type_id.produkt_type_unikt
          })
        }

        // console.log(this.products)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')
    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.checkPermissions()
      this.retrieveDateTimeFromServer()
      this.retrieveProducts()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
