
import { Options, Vue } from 'vue-class-component'
import shop from '@/components/Memberportal/Shop/index.vue'

@Options({
  components: {
    shop
  }
})

export default class Products extends Vue {}
